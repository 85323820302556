import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

// import commonStore from './stores/commonStore';
import RootStore from './stores/RootStore';
import config from './config';
// import i18n from '../i18n';

const { API_ROOT } = config;
const superagent = superagentPromise(_superagent, global.Promise);


const handleErrors = (err, response, skipErrors) => {
  if (!err) {
    // no error.
    return null;
  }

  if (err && err.response && err.response.status === 401) {
    // authStore.logout();
    if (RootStore.generalStore) RootStore.generalStore.addNotification('Authentication error', null, 'error');
    // return new Error('Authentication error');
  }
  let text = 'Unknown error connecting to the server';
  if (err
    && err.response
    && err.response.body
    && err.response.body.errors
    && err.response.body.errors.error
    && typeof err.response.body.errors.error == 'string') {
    text = err.response.body.errors.error;
  }
  if (err
    && err.response
    && err.response.body
    && err.response.body.errors
    && err.response.body.errors.message) {
    text = err.response.body.errors.message;
  }
  console.log('ERR', err, response);
  // if (commonStore && !skipErrors && ) commonStore.addNotification(i18n.t(text), null, 'error');
  return new Error(text);
};

const responseBody = res => res.body;

const tokenPlugin = req => {
  if (RootStore.authStore.token) {
    req.set('authorization', `Token ${RootStore.authStore.token}`);
  }
};

const convertToGetParams = params => Object.keys(params).reduce((total, currentValue, currentIndex, arr) => {
  let param = JSON.stringify(params[currentValue]);
  if (!param) {
    return total;
  }
  if (param.indexOf('{') < 0 && param.indexOf('[') < 0) param = param.replace('"', '').replace('"', '');
  return `${total}${currentValue}=${param}&`;
}, '?');

const requests = {
  del: url => superagent
    .del(`${API_ROOT}${url}`)
    .use(tokenPlugin)
    .end(handleErrors)
    .then(responseBody),
  get: (url, skipErrors) => superagent
    .get(`${API_ROOT}${url}`)
    .use(tokenPlugin)
    .end((e, r) => handleErrors(e, r, skipErrors))
    .then(responseBody),
  put: (url, body) => superagent
    .put(`${API_ROOT}${url}`, body)
    .use(tokenPlugin)
    .end(handleErrors)
    .then(responseBody),
  post: (url, body, skipErrors) => superagent
    .post(`${API_ROOT}${url}`, body)
    .use(tokenPlugin)
    .end((e, r) => handleErrors(e, r, skipErrors))
    .then(responseBody),
};


const Auth = {
  login: ({ email, password }) => requests.post('/users/login', { user: { email, password } })
};

const Tasks = {
  getTasks: ({ pagesize, page, sort, filters, mode }) => requests.post(`/tasks/list/${mode}`, { pagesize, page, sort, filters }),
  getTaskById: (id) => requests.get(`/tasks/${id}`),
  create: (task) => requests.post('/tasks', task),
  update: (taskId, task) => requests.put(`/tasks/${taskId}`, task),
  sendComment: (taskId, comment) => requests.put(`/tasks/comment/${taskId}`, { comment } ),
  transition: ({taskId, transitionName, transitionData}) => requests.put(`/tasks/transition/${taskId}/${transitionName}`, transitionData),
  remove: (id) => requests.del(`/tasks/${id}`),
};

const Users = {
  getById: (id) => requests.get(`/users/${id}`),
  getUsers: ({ pagesize, page, sort, filters }) => requests.post('/users/list', { pagesize, page, sort, filters }),
  getUserStat: () => requests.get('/users/stat'),
  create: (data) => requests.post('/users', data),
  update: (id, data) => requests.put(`/users/${id}`, data),
  remove: (id) => requests.del(`/users/${id}`),
};

const General = {
  getTasksConfig: () => requests.get('/general/tasks-config'),
  getInvoicesConfig: () => requests.get('/general/invoices-config'),
  getCourses: () => requests.get('/general/courses'),
  getAccounts: () => requests.get('/general/accounts'),
  getSettings: () => requests.get('/general/settings'),
  updateSettings: (id, data) => requests.put(`/general/settings/${id}`, data),
  getUsergroups: () => requests.get('/general/usergroups'),
  getServices: () => requests.get('/general/services'),

  lookup: ({ field, value }) => requests.get(`/general/lookup/${field}${convertToGetParams({name: value})}`)
};

const Attachment = {
  getFile: id => requests.get(`/attachments/${id}`),
  newFile: params => requests.post('/attachments', params),
  updateFile: (id, body) => requests.put(`/attachments/${id}`, body),
  deleteFile: id => requests.del(`/attachments/${id}`)
};

const Clients = {
  getById: (id) => requests.get(`/clients/${id}`),
  getClients: ({ pagesize, page, sort, filters }) => requests.post('/clients/list', { pagesize, page, sort, filters }),

  create: (data) => requests.post('/clients', data),
  update: (id, data) => requests.put(`/clients/${id}`, data),
  remove: (id) => requests.del(`/clients/${id}`),
};

const Accounting = {
  getList: () => requests.get(`/general/accounts/`),
  getCourses: () => requests.get('/general/courses'),
  createAccount: (data) => requests.post('/general/accounts/', data),
  updateAccount: (id, data) => requests.put(`/general/accounts/${id}`, data),
  createCourse: (data) => requests.post('/general/courses/', data),
  updateCourse: (id, data) => requests.put(`/general/courses/${id}`, data),
  getDebts: () => requests.get(`/tasks/debtsummary`),
  removeAccount: (id) => requests.del(`/general/accounts/${id}`)
};

const Invoices = {
  getById: (id) => requests.get(`/invoices/${id}`),
  getTasks: () => requests.post(`/tasks/list/create-invoice`, {
    "pagesize": 1000,
    "page": 0,
    "sort": [
      "created_at desc"
    ],
    "filters": {}
  }),
  getUserTasks: (id) => requests.post(`/tasks/list/create-invoice`, {
    "pagesize": 1000,
    "page": 0,
    "sort": [
      "created_at desc"
    ],
    "filters": {},
    "partner_id": id
  }),
  remove: (id) => requests.del(`/invoices/${id}`),
  getList: ({ pagesize, page, sort, filters }) => requests.post('/invoices/list', { pagesize, page, sort, filters }),
  create: (data) => requests.post('/invoices', data),
  update: (id, data) => requests.put(`/invoices/${id}`, data),
  makeInvoice: (data) => requests.put('/invoices/make', data),
  sendComment: (id, data) =>requests.post(`/invoices/comment/${id}`, data),
  transition: (taskId, transitionName, transitionData) => requests.put(`/invoices/transition/${taskId}/${transitionName}`, transitionData)
};
// const User = {
//   lookup: ({ email, password }) => requests.get(`/general/lookup/${field}`, { user: { email, password } })
// };
const Calendars = {
  getList: () => requests.get('/general/calendars'),
  getById: (id) => requests.get(`/general/calendars/${id}`),
  create: (data) => requests.post(`/general/calendars/`, data),
  update: (id, data) => requests.put(`/general/calendars/${id}`, data),
  getTasksList: () => requests.post('/tasks/list/tasks_for_calendar')
};

export default {
  Auth,
  Tasks,
  General,
  Users,
  Attachment,
  Clients,
  Accounting,
  Invoices,
  Calendars,
  convertToGetParams
};
