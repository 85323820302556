import { types as t, onSnapshot } from "mobx-state-tree"
import agent from '../agent';
import sentry from '../../utils/monitoring';

import { UserStore } from './UserStore';
import { AuthStore } from './AuthStore';
import { TaskStore } from './TaskStore';
import { GeneralStore } from './GeneralStore';
import { ClientStore } from './ClientStore';
import { AccountStore } from './AccountStore';
import { InvoiceStore } from './InvoiceStore';
import { CalendarStore } from './CalendarStore';

const Model = {
  authStore: t.optional(AuthStore, {}),
  generalStore: t.optional(GeneralStore, {}),
  userStore:  t.optional(UserStore, {}),
  taskStore: t.optional(TaskStore, {}),
  clientStore: t.optional(ClientStore, {}),
  accountStore: t.optional(AccountStore, {}),
  invoiceStore: t.optional(InvoiceStore, {}),
  calendarStore: t.optional(CalendarStore, {})
};

const Actions = (self) => ({
  
  
  afterCreate() {
    console.log('root store created', self);
  }
});

const RootStore = t
  .model("RootStore", Model)
  .actions(Actions);


const store = RootStore.create(
  {},
  {
    api: agent,
    monitoring: sentry
  }
);

onSnapshot(store, newSnapshot => {
 // console.log("STORE LOGGER: ", newSnapshot)
});

export default store;

