const API_ROOT = window.location.hostname === 'localhost' && window.location.port && window.location.port!= 81
  ? 'http://localhost:3000/api/v2'
  : '/api/v2';

const UPLOADS_PATH = window.location.hostname === 'localhost' && window.location.port && window.location.port!= 81
  ? 'http://localhost:3000/api/uploads'
  : `${window.location.origin}/api/uploads`;

export default {
  API_ROOT,
  UPLOADS_PATH
};
