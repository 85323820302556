import moment from 'moment';
import i18n from "./i18n";

const MAIN_DATE_PATTERN = 'DD, MMMM, YYYY';

const renderDateSafe = (date, pattern = MAIN_DATE_PATTERN) => {
  if (date === 0) {
    return '-'
  }

  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.format(pattern);
  }

  return '-';
};

const dateToISOSafe = (date) => {
  if (date === 0) {
    return date;
  }

  if (moment.isMoment(date)) {
    return date.toISOString();
  }

  if (date instanceof Date) {
    return date.toISOString();
  }

  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.toISOString();
  }


  return null;
};

const dateDiffFromNow = (date, diffIn = 'days') => {
  const a = moment().startOf('day');
  const b = moment(date);

  return b.diff(a, diffIn);
};

const getDaysStringFromDiff = (diff = 0, showAsDate = false) => {
  if (showAsDate) {
    return moment(showAsDate).format('DD.MM.YYYY');
  }
  const labels = [i18n.t('день'), i18n.t('дня'), i18n.t('дней')];
  const n = Math.abs(diff) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) {
    return `${diff} ${labels[2]}`;
  }
  if (n1 > 1 && n1 < 5) {
    return `${diff} ${labels[1]}`;
  }
  if (n1 === 1) {
    return `${diff} ${labels[0]}`;
  }
  return `${diff} ${labels[2]}`;
};

Date.prototype.addDays = function(days) {
  const dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

export { renderDateSafe, dateToISOSafe, dateDiffFromNow, getDaysStringFromDiff };
