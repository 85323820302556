import * as Sentry from '@sentry/browser';

const sentryConfig = {
  dsn: "https://67129b3fd2434ab0bcda8bc0828696b9@sentry.io/1840558",
  environment: process.env.NODE_ENV
};

Sentry.init(sentryConfig);

export default Sentry;
