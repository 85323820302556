import { types as t, getEnv, flow } from "mobx-state-tree"

const AccountsModel = t.model("AccountsModel", {
  isLoading: false,
  list: t.array(t.frozen())
});

const CourseModel = t.model("CourseModel", {
  isLoading: false,
  list: t.array(t.frozen())
});

const DebtModel = t.model("DebtModel", {
  isLoading: false,
  data: t.frozen()
});

const Model = {
  accountsList: t.optional(AccountsModel, {isLoading: false, list: []}),
  coursesList: t.optional(CourseModel, {isLoading: false, list: []}),
  debts: t.optional(DebtModel, {isLoading: false, data: {authors:0, editors: 0}}),
  detailedUpdateError: t.frozen()
};

const Actions = (self) => {

  const getAccountsAsync = flow(function* () {
    try {
      const { getList } = getEnv(self).api.Accounting;
      self.accountsList.isLoading = true;

      const res = yield getList();

      self.accountsList.list = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getDebtsAsync = flow(function* () {
    try {
      const { getDebts } = getEnv(self).api.Accounting;
      self.debts.isLoading = true;

      const res = yield getDebts();

      self.debts.data = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getCoursesAsync = flow(function* () {
    try {
      const { getCourses } = getEnv(self).api.Accounting;
      self.coursesList.isLoading = true;

      const res = yield getCourses();

      self.coursesList.list = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });


  const saveAccountAsync = flow(function* (data) {
    try {
      self.detailedUpdateError = false;
      const {updateAccount, createAccount} = getEnv(self).api.Accounting;
      if (data.id) {
        yield updateAccount(data.id, data);
      } else {
        yield createAccount(data);
      }
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUpdateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const saveCourseAsync = flow(function* (data) {
    try {
      self.detailedUpdateError = false;
      const {updateCourse, createCourse} = getEnv(self).api.Accounting;
      if (data.id) {
        yield updateCourse(data.id, data);
      } else {
        yield createCourse(data);
      }
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUpdateError = error.response && error.response.body && error.response.body.errors;
    }
  });


  const removeAccountAsync = flow(function* (id) {
    try {
      const { removeAccount } = getEnv(self).api.Accounting;

      yield removeAccount(id);

    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });



  return {getAccountsAsync, getCoursesAsync, saveAccountAsync, saveCourseAsync, getDebtsAsync, removeAccountAsync}
};

const Views = (self) => ({

});

export const AccountStore = t
  .model("AccountStore", Model)
  .actions(Actions)
  .views(Views);

