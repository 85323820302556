import _transform from 'lodash/transform';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';

function difference(object, base) {
  function changes(object, base) {
    return _transform(object, function(result, value, key) {
      if (!_isEqual(value, base[key])) {
        result[key] = (_isObject(value) && _isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}


export { difference };
