import {types as t, flow, getEnv, getParent} from "mobx-state-tree"
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import calendarMock from '../../assets/mocks/calendar';
import i18n from "../../utils/i18n";

let lastSaveRequestTime = 0;
let isNowSaving = false;
let lastSaveTime = 0;

const DaysItemModel = t.model("DaysItemModel", {
  tz: t.frozen(),
  date: t.frozen()
});

const DataItemModel = t.model("DataItem", {
  days: t.array(DaysItemModel)
});

const CalendarItemModel = t.model("CalendarItem", {
  "id": t.frozen(),
  "user_id": t.frozen(),
  "balance": t.maybeNull(t.number),
  "comment": t.frozen(),
  "schedule": t.frozen(),
  "comment_from_manager": t.frozen(),
  "createdAt": t.frozen(),
  "updatedAt": t.frozen(),

  "user": t.frozen(),
  // "firstname":  t.frozen(),
  // "lastname":  t.frozen(),
  // "priority": t.frozen(),
  // id: 163
  // role: "author"

  // "days": t.array(t.frozen()),
  "data": t.optional(DataItemModel, {days: []}),
});

const Model = {
  calendarList: t.optional(t.array(CalendarItemModel), []),
  calendarListPrepared: t.optional(t.array(CalendarItemModel), []),
  calendarUserDetailed: t.optional(CalendarItemModel, {}),
  calendarTasks: t.frozen(),
  listLoading: false
};

const Actions = (self) => {
  const getCalendarDataAsync = flow(function* () {
    try {
      const response = yield getEnv(self).api.Calendars.getList();

      self.calendarList = response;
    } catch (error) {
      console.log(error);
    }
  });

  const getCalendarDataByIdAsync = flow(function* () {
    const response = yield getEnv(self).api.Calendars.getList();

    if (response && response.length) {
      self.calendarUserDetailed = response[0];
    } else {
      const user = getParent(self).authStore.me;

      self.calendarUserDetailed = {
        "id": false,
        "user_id": user.id,
        "balance": 0,
        "comment": "",
        "schedule": "",
        "comment_from_manager": "",
        "data": {
          "days": []
        }
      }
    }
  });

  const saveUserCalendarInline = async (delayed) => {
    if (!delayed) lastSaveRequestTime = new Date().getTime();
    if (lastSaveTime > lastSaveRequestTime) return;
    setTimeout(async ()=>{
      if (lastSaveTime > lastSaveRequestTime) return;
      if (isNowSaving || lastSaveRequestTime >= (new Date().getTime() - 1000)) {
        self.saveUserCalendarInline(true);
      } else {
        isNowSaving = true;
        await self.saveUserCalendar();
        lastSaveTime = new Date().getTime();
        isNowSaving = false;
      }
    }, 1000);
  }

  const saveUserCalendar = flow(function* (data) {
    try {
      if (!data) {
        data = self.calendarUserDetailed.toJSON();
      }

      if (!data.id) {
        const response = yield getEnv(self).api.Calendars.create(data);

        if (response) {
          self.calendarUserDetailed = response;
        }

      } else {
        yield getEnv(self).api.Calendars.update(data.id, data);
      }

    } catch (error) {
      console.log(error);
    }
  });

  const ListByIdCache = {};

  const saveWorkloadInternally = (id, balance) => {
    self.saveUserCalendar({id, balance: balance});
  }
  const debouncedSaveWorkloadInternally = _debounce(saveWorkloadInternally, 1000, {maxWait: 2000, trailing:true});

  const updateWorkload = (workload, _id) => {
    const balance = parseInt(workload, 10);
    const rowIndex = ListByIdCache[_id] || _findIndex(self.calendarList, r => r.id === _id);
    ListByIdCache[_id] = rowIndex;
    self.calendarList[rowIndex].balance = Number.isInteger(balance) ? balance : 0;
    //saveUpdateManagerCommentInternally(_id, comment);
    debouncedSaveWorkloadInternally(_id, balance);
  };

  const saveUpdateManagerCommentInternally = (id, comment) => {
    self.saveUserCalendar({id, comment_from_manager: comment});
  }
  const debouncedSaveUpdateManagerCommentInternally = _debounce(saveUpdateManagerCommentInternally, 1000, {maxWait: 2000, trailing:true});

  const updateManagerComment = (comment, _id, doSave) => {
    const rowIndex = ListByIdCache[_id] || _findIndex(self.calendarList, r => r.id === _id);
    ListByIdCache[_id] = rowIndex;
    self.calendarList[rowIndex].comment_from_manager = comment;
    //saveUpdateManagerCommentInternally(_id, comment);
    debouncedSaveUpdateManagerCommentInternally(_id, comment);
  };

  const updateCalendarUserLoad = (date, load) => {
    const day = self.calendarUserDetailed.data.days.find(v => v.date === date);
    const tzLoad = isNaN(parseInt(load, 10)) ? null : parseInt(load, 10);

    if (day) {
      day.tz = tzLoad;
    } else {
      self.calendarUserDetailed.data.days.push({tz: tzLoad, date})
    }
    self.saveUserCalendarInline();
  };

  const updateCalendarUserBalance = (balance) => {
    self.calendarUserDetailed.balance = parseInt(balance, 10);
    self.saveUserCalendarInline();
  };

  const updateCalendarUserField = (value, field) => {
    self.calendarUserDetailed[field] = value;
    self.saveUserCalendarInline();
  };

  const getCalendarTasksAsync = flow(function* () {
    try {
      const { getTasksList } = getEnv(self).api.Calendars;
      const res = yield getTasksList();
      self.calendarTasks = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });


  return {
    getCalendarDataAsync,
    getCalendarTasksAsync,
    updateWorkload,
    getCalendarDataByIdAsync,
    saveUserCalendar,
    saveUserCalendarInline,
    updateCalendarUserLoad,
    updateCalendarUserBalance,
    updateCalendarUserField,
    updateManagerComment
  }
};

const Views = (self) => ({
  get dow() {
    return   [i18n.t("Вс"), i18n.t("Пн"), i18n.t("Вт"), i18n.t("Ср"),
      i18n.t("Чт"), i18n.t("Пт"), i18n.t("Сб")];
  },

  getNiceDayName(dt) {
    return self.dow[dt.getDay()] + " " + dt.getDate();
  },

  getDayCode(dt) {
    return "" + dt.getFullYear() + "/" + (dt.getMonth() < 10 ? "0" : "") + (dt.getMonth() + 1) + "/" + (dt.getDate() < 10 ? "0" : "") + dt.getDate();
  },

  generateNextDays(daysAmount) {
    const res = [];
    let dt = new Date();
    for (let i = 0; i < daysAmount; i++) {

      res.push({
        Header: self.getNiceDayName(dt),
        isNormalDayOff: (dt.getDay() === 0 || dt.getDay() === 6),
        accessor: self.getDayCode(dt),
        // width: 40,
      });

      dt = dt.addDays(1);
    }

    return res;
  },

  fillDays(generatedDays, calendarItem) {
    let res = {};
    let balance = calendarItem.balance;
    //console.log(balance, generatedDays)

    for (let i = 0; i < generatedDays.length; i++) {
      const day = generatedDays[i];

      let daydata = {
        class: "no-data",
        tz: "",
        date: day.accessor,
        balance: ""
      };

      // console.log(calendarItem.data.days);
      let singledaydata = _find(calendarItem.data.days, function(d) {
        if (d.date === day.accessor) {
          return d;
        }
      });

      if (singledaydata) {
        daydata.tz = singledaydata.tz;
        console.log('D,', daydata.date, daydata.tz)
      }

      if (daydata.tz === "" || daydata.tz === null) {
        daydata.balance = "";
      } else if (balance >= daydata.tz) {
        daydata.balance = 0;
        balance -= daydata.tz;
      } else {
        daydata.balance = daydata.tz - balance;
        balance = 0;
      }

      if (daydata.tz === "" || daydata.tz === null) {
        daydata.class = "no-data";
      } else if (daydata.tz === 0) {
        daydata.class = "dayoff";
      } else {
        daydata.class = daydata.balance === 0 ? "full" : "partial";
      }
      res[day.accessor] = daydata;
    }

    return res;
  },

  get calendarData() {
    const columns = self.generateNextDays(30);

    const data = self.calendarList.map(calendarItem => {
      const calculatedCells = self.fillDays(columns, calendarItem);
if (calendarItem.user.id==50) {
  console.log('!!!', JSON.parse(JSON.stringify(calendarItem.data.days)), calculatedCells);
}
      return {
        ...calendarItem,
        ...calculatedCells,
        fullName: [calendarItem.user.firstname, calendarItem.user.lastname].join(' '),
        price: _get(calendarItem, 'user.data.price', ''),
        city: _get(calendarItem, 'user.city', ''),
        user: {
          ...calendarItem.user,
          fullName: [calendarItem.user.firstname, calendarItem.user.lastname].join(' '),
        },
        priority: _get(calendarItem, 'user.priority', 0)
      }
    });

    return {columns, data};
  },

  get preparedCalendarUserData() {
    const columns = self.generateNextDays(30);
    let data;

    if (self.calendarUserDetailed && self.calendarUserDetailed.user_id) {
      const calculatedCells = self.fillDays(columns, self.calendarUserDetailed);
      data = {
        ...self.calendarUserDetailed,
        ...calculatedCells
      }
    }

    return {columns, data};
  }
});

const CalendarStore = t
  .model("CalendarStore", Model)
  .actions(Actions)
  .views(Views);


export { CalendarStore };
