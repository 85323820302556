import { types as t, flow, getEnv } from 'mobx-state-tree';
import i18n from '../../utils/i18n';

export const roles = {
  partner: i18n.t('Партнер'),
  admin: i18n.t('Админ'),
  manager: i18n.t('Менеджер'),
  editor: i18n.t('Редактор'),
  author: i18n.t('Автор'),
};

const User = t.model('User', {
  id: t.identifierNumber,
  m_id: t.maybeNull(t.string),
  image: t.maybeNull(t.string),
  added: t.maybeNull(t.string),
  role: t.string,
  email: t.string,

  skype: t.maybeNull(t.string),
  icq: t.maybeNull(t.string),
  website: t.maybeNull(t.string),
  city: t.maybeNull(t.string),
  specialization: t.maybeNull(t.string),
  favorites: t.maybeNull(t.string),
  notes: t.maybeNull(t.string),
  director: t.maybeNull(t.boolean),
  priority: t.maybeNull(t.string),
  permissions: t.maybeNull(t.string),
  attachments: t.maybeNull(t.string),
  data: t.frozen(),

  firstname: t.maybeNull(t.string),
  lastname: t.maybeNull(t.string),
  status: t.optional(t.enumeration('Status', ['active', '']), ''),

  requisites: t.maybeNull(t.string),

  updated_at: t.frozen(),
  created_at: t.frozen(),

  phone: t.maybeNull(t.string),
  username: t.string,

  client_id: t.frozen(),

  payed: t.frozen(),
  toPay: t.frozen(),
  total: t.frozen(),
});

const LookupUsersModel = t.model('LookupUsersModel', {
  editors: t.optional(t.frozen(), []),
  authors: t.optional(t.frozen(), []),
  managers: t.optional(t.frozen(), []),
  partners: t.optional(t.frozen(), []),
  clients: t.optional(t.frozen(), []),

  editorsIsLoading: false,
  authorsIsLoading: false,
  managersIsLoading: false,
  partnersIsLoading: false,
  clientsIsLoading: false,
});

const Model = {
  usersListIsLoading: false,
  usersList: t.array(User),
  managersLookup: t.frozen(),
  authorsLookup: t.frozen(),
  editorsLookup: t.frozen(),
  partnersLookup: t.frozen(),
  clientsLookup: t.frozen(),
  currentParams: t.frozen(),
  pages: t.optional(t.number, 0),
  total: t.optional(t.number, 0),

  lookupUsersData: t.optional(LookupUsersModel, {}),

  detailedUser: t.frozen(),
  detailedUserError: t.frozen(),
  
  userStat: t.frozen(),};

const Actions = (self) => {

  const getUserStat = flow(function* () {
    const { getUserStat } = getEnv(self).api.Users;
    const res = yield getUserStat();
    self.userStat = res;
  })

  const lookupUsers = flow(function* ({ field, value } = {}) {
    try {
      const { lookup } = getEnv(self).api.General;

      if (field === 'author') {
        self.lookupUsersData.authorsIsLoading = true;
        const response = yield lookup({ field, value });
        self.lookupUsersData.authors = response.lookup;
        self.authorsLookup = response.lookup;
        self.lookupUsersData.authorsIsLoading = false;
      }

      if (field === 'editor') {
        self.lookupUsersData.editorsIsLoading = true;
        const response = yield lookup({ field, value });
        self.editorsLookup = response.lookup;
        self.lookupUsersData.editors = response.lookup;
        self.lookupUsersData.editorsIsLoading = false;
      }

      if (field === 'manager') {
        self.lookupUsersData.managersIsLoading = true;
        const response = yield lookup({ field, value });
        self.managersLookup = response.lookup;
        self.lookupUsersData.managers = response.lookup;
        self.lookupUsersData.managersIsLoading = false;
      }

      if (field === 'partner') {
        self.lookupUsersData.partnersIsLoading = true;
        const response = yield lookup({ field, value });
        self.partnersLookup = response.lookup;
        self.lookupUsersData.partners = response.lookup;
        self.lookupUsersData.partnersIsLoading = false;
      }

      if (field === 'client' || field === 'managerClient') {
        self.lookupUsersData.clientsIsLoading = true;
        const response = yield lookup({ field, value });
        self.clientsLookup = response.lookup;
        self.lookupUsersData.clients = response.lookup;
        self.lookupUsersData.clientsLoading = false;
      }

    } catch (error) {
      getEnv(self).monitoring.captureMessage('Lookup Users Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getUsersAsync = flow(function* ({
    pagesize = 10,
    page = 0,
    sort = [],
    filters = {},
  } = {}) {
    try {
      self.usersListIsLoading = true;
      const { getUsers } = getEnv(self).api.Users;
      const res = yield getUsers({ pagesize, page, sort, filters });

      self.usersList = res.rows;
      self.pages = res.pages;
      self.total = res.total;
      self.currentParams = { pagesize, page, sort, filters };

      self.usersListIsLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Users Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getUserByIdAsync = flow(function* (userId) {
    try {
      self.usersListIsLoading = true;
      const { getById } = getEnv(self).api.Users;
      const res = yield getById(userId);
      if (res.user.role === 'admin' && !res.user.director) {
        res.user.role = 'manager';
      }
      self.detailedUser = res.user;
      self.usersListIsLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading User Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });
  const returnUserByIdAsync = flow(function* (userId) {
    try {
      self.usersListIsLoading = true;
      const { getById } = getEnv(self).api.Users;
      const res = yield getById(userId);
      if (res.user.role === 'admin' && !res.user.director) {
        res.user.role = 'manager';
      }
      return res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading User Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const updateUserAsync = flow(function* ({ userId, data }) {
    try {
      self.detailedUserError = false;
      const { update } = getEnv(self).api.Users;
      return yield update(userId, data);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating User Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUserError =
        error.response && error.response.body && error.response.body.errors;
    }
  });

  const createUserAsync = flow(function* (data) {
    try {
      self.detailedUserError = false;
      const { create } = getEnv(self).api.Users;

      return yield create(data);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating User Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUserError =
        error.response && error.response.body && error.response.body.errors;
    }
  });

  const removeAsync = flow(function* (id) {
    try {
      const { remove } = getEnv(self).api.Users;
      yield remove(id);
      getUsersAsync(self.currentParams);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const clearDetailed = () => {
    self.detailedUser = false;
  };

  return {
    getUserStat,
    lookupUsers,
    getUsersAsync,
    getUserByIdAsync,
    updateUserAsync,
    createUserAsync,
    clearDetailed,
    removeAsync,
    returnUserByIdAsync
  };
};

const Views = (self) => ({
  get usersTableData() {
    const getRole = (user) => {
      if (user.role !== 'admin') return user.role;
      return user.director ? 'admin' : 'manager';
    };

    return self.usersList.map((user) => {
      return {
        ...user,
        name: [user.firstname, user.lastname].join('  '),
        role: roles[getRole(user)],
      };
    });
  },

  get rolesList() {
    return Object.keys(roles)
      .map((k) => {
        return { key: [k], value: roles[k] };
      })
      .reverse();
  },
});

const UserStore = t.model('UserStore', Model).actions(Actions).views(Views);

export { User, UserStore };
