import translations from './translations.json'

const I18nCustom = {
    getLocales () {
        return Object.keys(translations).filter(k => k !== 'KEY')
    },
    t: (str) => {
        if (!str) return "";
        const lang = window.localStorage.getItem("locale") || 'ru';
        if (translations[lang] && translations[lang][str]) {
            return translations[lang][str];
        } else if (translations.ru[str]) {
            return translations.ru[str];
        }
        return str;
    }
};

export default I18nCustom;
