import {types, getEnv, flow, getParent} from "mobx-state-tree"
import { find } from 'lodash';
import i18n from "../../utils/i18n";

const Model = {
  isLoaded: false,
  tasksConfig: types.optional(types.frozen(), {}),
  invoicesConfig: types.optional(types.frozen(), {}),
  courses: types.frozen(),
  services: types.optional(types.frozen(), []),
  settings: types.frozen(),
  usergroups: types.frozen(),
  notifications: types.array(types.frozen()),
  _displayedUids: types.array(types.frozen())
};

const Actions = (self) => {
  const fileUpload = flow(function* (fileParams) {

    try {
      const { newFile } = getEnv(self).api.Attachment;
      return yield newFile(fileParams);
    } catch (error) {
      console.log('error', error);
    }
  });
  const getFileById = flow(function* (id) {

    try {
      const { getFile } = getEnv(self).api.Attachment;
      return yield getFile(id);

    } catch (error) {
      console.log('error', error);
    }
  });

  const updateFile = flow(function* (id, body) {

    try {
      const { updateFile } = getEnv(self).api.Attachment;
      return yield updateFile(id, body);

    } catch (error) {
      console.log('error', error);
    }
  });

  const deleteFile = flow(function* (id) {

    try {
      const { deleteFile } = getEnv(self).api.Attachment;
      return yield deleteFile(id);

    } catch (error) {
      console.log('error', error);
    }
  });


  const loadAppDataAsync = flow(function* () {
    self.isLoaded = false;

    try {
      const {getTasksConfig, getInvoicesConfig, getCourses, getAccounts, getSettings, getUsergroups, getServices} = getEnv(self).api.General;
      const promises = [getTasksConfig(), getInvoicesConfig(), getCourses(), getServices(), getSettings(), getUsergroups()];

      const [tasksConfig, invoicesConfig, courses, services, settings, usergroups] = yield Promise.all(promises);

      self.tasksConfig = tasksConfig;
      self.invoicesConfig = invoicesConfig;
      self.courses = courses;
      // self.accounts = accounts;
      self.services = services;
      self.settings = settings;
      self.usergroups = usergroups;

      self.isLoaded = true;
    } catch (error) {

    }
  });

  const updateSettingsAsync = flow(function* (data) {
    console.log('In update settings async');
    self.isLoaded = false;
    try {
      const { updateSettings, getSettings } = getEnv(self).api.General;
      const updateResponse = [];
      for (let i = 0; i < data.length; i++) {
        const thisUpdateResponse = yield updateSettings(data[i].id, data[i]);
        updateResponse.push(thisUpdateResponse);
      }

      self.settings = yield getSettings();
      self.isLoaded = true;

      return updateResponse;
    } catch (error) {

    }
  });
  const addNotification = (
    message,
    title = null,
    level = 'info',
    position = 'tr',
    autoDismiss = 3
  )  => {
    const uid = new Date().getTime().toString();

    self.notifications.unshift({
      uid,
      title,
      message,
      level,
      position,
      autoDismiss,
      onAdd: n => {
        const count = self.notifications.length || 0;
        self._displayedUids.unshift(n.uid);

        if(count > 30){
          self.notifications.pop();
          self._displayedUids.pop();
        }
      }
    })
  };

  return {loadAppDataAsync, updateSettingsAsync, fileUpload, getFileById, updateFile, deleteFile, addNotification}
};

const Views = (self) => {
  const PendingNotifications = () => {
    return self.notifications.filter(notif => !self._displayedUids.includes(notif.uid));
  };

  const getServicesForSelect = () => {
    return self.services.map((value) => {
      return value
    });
  };

  const getProcessForSelect = () => {
    const { workflowNames } = self.tasksConfig;

    if (!workflowNames) {
      return [];
    }

    return Object.keys(workflowNames).map((key) => {
      return {
        value: key,
        label: i18n.t(workflowNames[key])
      }
    });
  };

  const getProcessByIdForSelect = (workflowKey) => {
    const { workflowNames } = self.tasksConfig;

    if (!workflowNames) {
      return {};
    }

    const workflow = workflowNames[workflowKey];

    if (!workflow) {
      return {};
    }

    return {
      value: workflowKey,
      label: i18n.t(workflow)
    }
  };

  const getSpecialFiltersByName = (name = 'by_dept') => {
    const { filters } = self.tasksConfig;

    if (!filters) {
      return [];
    }

    return [{value: 'all', label: i18n.t('Все')}, ...Object.values(filters[name]).map((value) => {
      return {
        value: value.id,
        label: i18n.t(value.name)
      }
    })]

  };

  const getTaskStatuses = () => {
    const { names } = self.tasksConfig;
    const me = getParent(self).authStore.me;

    if (!names) {
      return [];
    }
    const normalStatuses = [];
    Object.keys(names).forEach((key) => {
      if (key === 'sentToClient' && (me.role === 'author' || me.role === 'editor')) {
        return;
      }
      normalStatuses.push({
        value: key,
        label: i18n.t(names[key])
      });
    });
    return [{value: 'all', label: i18n.t('Все')}, ...normalStatuses, {value: 'uncompleted', label: i18n.t('Незавершенные')}, {value: 'dashboard', label: i18n.t('Дашборд')}];
  };

  const getInvoiceStatuses = () => {
    const { names } = self.invoicesConfig;

    if (!names) {
      return [];
    }

    return [{value: 'all', label: i18n.t('Все')}, ...Object.keys(names).map((key) => {
      return {
        value: key,
        label: i18n.t(names[key])
      }
    })];
  };

  const getCourses = () => {
    const { courses } = self;

    if (!courses) {
      return [];
    }

    return Object.values(courses).map((val) => {
      return val
    });
  };

  const getTaskStatusNameById = (id) => {
    const { names } = self.tasksConfig;

    if (names && names[id]) {
      return i18n.t(names[id]);
    }

    return '';
  };

  const getInvoiceStatusNameById = (id) => {
    const { names } = self.invoicesConfig;

    if (names && names[id]) {
      return i18n.t(names[id]);
    }

    return '';
  };

  const getTransitionNameById = (id, workflow = 'aemc') => {
    const { definitions } = self.tasksConfig;
    const { transitions } = definitions[workflow] ? definitions[workflow] : definitions['aemc'];
    const _transition = find(transitions, r => r.name === id);
    if (_transition) {
      return i18n.t(_transition.title);
    }

    return '';
  };

  const getSettings = () => {
    const { settings } = self;

    if (!settings) {
      return {}
    }

    const settingsObj = {};

    settings.forEach(v => {
      settingsObj[v.name] = v;
    });

    return settingsObj;
  };

  return {
    getServicesForSelect,
    getProcessForSelect,
    getSpecialFiltersByName,
    getTaskStatuses,
    getInvoiceStatuses,
    getCourses,
    getTaskStatusNameById,
    getInvoiceStatusNameById,
    getProcessByIdForSelect,
    getTransitionNameById,
    getSettings,
    PendingNotifications
  }
};

export const GeneralStore = types
  .model("GeneralStore", Model)
  .actions(Actions)
  .views(Views);
