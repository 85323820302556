import {types as t, getEnv, flow, getParent} from "mobx-state-tree"
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import {renderDateSafe, dateToISOSafe, dateDiffFromNow} from '../../utils/date';
import {difference} from '../../utils/object';

// import {User} from './UserStore';
//import tasks from '../../assets/mocks/tasks';

// const PriceModel = t.model("PriceModel", {
//   author: t.number,
//   client: t.number,
//   editor: t.number,
//   clientCurrency: t.string,
// });
//
// const DeadlineModel =  t.model("DeadlineModel", {
//   author: t.number,
//   client: t.number,
//   editor: t.number
// });
//
// const CurrencyModel =  t.model("CurrencyModel", {
//   id: t.identifier,
//   name: t.string
// });
//
// const ClientModel = t.model("ClientModel",{
//   id: t.identifierNumber,
//   name: t.string,
// });
//
// const AuthorModel = t.model("AuthorModel",{
//   id: t.identifierNumber,
//   name: t.string,
//   role: t.string
// });
//
// const EditorModel = t.model("EditorModel", {
//   id: t.identifierNumber,
//   name: t.string,
//   role: t.string
// });
//
// const ParentTaskModel = t.model("ParentTaskModel", {
//   id: t.identifierNumber,
//   name: t.string
// });

// const prepareTaskData = (taskData) => {
//   return {
//     ...taskData,
//
//     data: {
//       ...taskData.data,
//       price: {
//         ...taskData.data.price,
//         clientCurrency: _get(taskData, 'data.price.clientCurrency.id'),
//       },
//       deadline: {
//         ...taskData.data.deadline,
//         author: dateToISOSafe(taskData.data.deadline.author),
//         editor: dateToISOSafe(taskData.data.deadline.editor),
//         client: dateToISOSafe(taskData.data.deadline.client)
//       }
//     },
//     workflow: _get(taskData, 'workflow.value'),
//     status: taskData.waitingForClient ? 'waitingForClient' : taskData.status
//   };
// };



export const AUTHOR_TRANSITIONS = ['assignAuthor', 'reassignAuthor']; // 'takeAsAuthor',
export const EDITOR_TRANSITIONS = ['assignEditor', 'reassignEditor']; // 'startAsEditor',
export const COMMENT_TRANSITIONS = ['cancelAsAuthor', 'cancelAsEditor'];
export const ENDING_TRANSITIONS = ['sendToClient', 'sendToClientFromWait'];

export const TRANSITIONS_THAT_REQUIRES_MODAL = AUTHOR_TRANSITIONS.concat(EDITOR_TRANSITIONS, COMMENT_TRANSITIONS, ENDING_TRANSITIONS);


const prepareTaskDataForSave = (taskData) => {
  // console.log('...', taskData);
  // throw new Error('!!!');
  return {
    "description": taskData.description,
    "name": taskData.name,
    "working_text": taskData.working_text,
    "generate_final_files": taskData.generate_final_files,
    "size": parseFloat(taskData.size),
    "data": {
      "price": {
        "client": parseFloat(_get(taskData, 'data.price.client', 0)),
        "author": parseFloat(_get(taskData, 'data.price.author', 0)),
        "editor": parseFloat(_get(taskData, 'data.price.editor', 0)),
        "partner": parseFloat(_get(taskData, 'data.price.partner', 0)),
        clientCurrency: _get(taskData, 'data.price.clientCurrency')
      },
      "service": _get(taskData, 'data.service'),
      "deadline": {
        author: dateToISOSafe(taskData.data.deadline.author),
        editor: dateToISOSafe(taskData.data.deadline.editor),
        client: dateToISOSafe(taskData.data.deadline.client)
      },
      // "attachments": _get(taskData, 'data.attachments', []),
      "waitingForClient": _get(taskData, 'data.waitingForClient', false)
    },
    status: taskData.waitingForClient ? 'waitingForClient' : taskData.status,
    "author": taskData.author,
    "client": taskData.client,
    "editor": taskData.editor,
    "manager": taskData.manager,
    "partner": taskData.partner,
    workflow: _get(taskData, 'workflow.value') || taskData.workflow,
  }
};

export const TaskModel = t.model("Task", {
  id: t.identifierNumber,
  name: t.maybeNull(t.string),
  description: t.maybeNull(t.string),
  status: t.maybeNull(t.string),
  size: t.maybeNull(t.number),
  workflow: t.maybeNull(t.string),
  parent_name: t.maybeNull(t.string),
  working_text: t.maybeNull(t.string),
  created_at: t.maybeNull(t.string),
  updated_at: t.maybeNull(t.string),
  data: t.frozen(),
  parent: t.frozen(),
  currency: t.frozen(),
  client: t.frozen(),
  editor: t.frozen(),
  author: t.frozen(),
  partner: t.frozen(),
  last_transition: t.frozen(),
  repeatsCount: t.frozen(),
  allComments: t.frozen(),
  last_comment_date: t.frozen()
});

const Model = {
  tasksIsLoading: false,
  currentParams:t.frozen(),
  tasks: t.optional(t.array(TaskModel), []),

  detailedTask: t.frozen(),
  detailedTaskTransitions: t.frozen(),

  pages: t.optional(t.number, 0),
  total: t.optional(t.number, 0),
};

const Actions = (self) => {
  const getTasks = flow(function* ({ pagesize = 10, page = 0, sort = [], filters = {}, parent, mode = 'tasks-page' } = {}) {

    try {
      if (self.tasksIsLoading) {
        return;
      }
      self.tasksIsLoading = true;
      const { getTasks } = getEnv(self).api.Tasks
      const tasksResponse = yield getTasks({pagesize, page, sort, filters, parent, mode });

      self.currentParams = {pagesize, page, sort, filters, mode};
      self.tasks = tasksResponse.rows;
      self.pages = tasksResponse.pages;
      self.total = tasksResponse.total;
      self.tasksIsLoading = false;

    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Tasks Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getClientTasksAsync = flow(function* ({pagesize = 10, page = 0, sort = [], filters = {}, mode = 'client-tasks'} = {}) {
    self.getTasks({pagesize, page, sort, filters, mode });
  });

  const getSubtasksAsync = flow(function* ({pagesize = 10, page = 0, sort = [], filters = {}, mode = 'subtasks', parent } = {}) {
    self.getTasks({pagesize, page, sort, filters, mode });
  });

  const getTaskById = flow(function* (id) {
    self.detailedTaskTransitions = null;
    try {
      const {getTaskById} = getEnv(self).api.Tasks;

      const response = yield getTaskById(id);
      self.detailedTask = response.task;
      self.detailedTaskTransitions = response.Transitions;

      return self.detailedTask;
    } catch (error) {
      getEnv(self).monitoring.addBreadcrumb({message: 'Creating Task Failed'});
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const updateFiles = flow(function* (attachments) {
    self.detailedTask = {...self.detailedTask, data: {...self.detailedTask.data, attachments}};

    return self.detailedTask;
  });

  const nextPage = ({page, sort = [], filters = {}}) => {
    self.getTasks({pagesize: 10, page, sort, filters});
  };

  const saveTask = flow(function* ({taskData, parentId}) {
    try {
      const {create} = getEnv(self).api.Tasks;
      const task = prepareTaskDataForSave(taskData);

      if (!task.status) {
        if (task.workflow === 'ec' || task.workflow === 'emc') {
          task.status = 'waitingForEditor';

          if (task.author) {
            task.status = 'givenToEditor';
          }
        } else {
          task.status = 'waitingForAuthor';

          if (task.author) {
            task.status = 'givenToAuthor'
          }
        }
      }

      if (parentId) {
        task.parent_id = parentId;
        task.parent = {
          id: parentId,
          name: self.detailedTask.name
        }
      }

      const res = yield create(task);

      return res.task;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating Task Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const updateTask = flow(function* ({taskId, taskData}) {
    try {
      const {update} = getEnv(self).api.Tasks;
      const task = prepareTaskDataForSave(taskData);
      const loadedTask = prepareTaskDataForSave(self.detailedTask);

      const diff = difference(task, loadedTask);

      if (!diff.status) {
        delete diff.status
      }
      const res = yield update(taskId, diff);

      return res.task;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Task Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const updateAttachments = flow(function* ({taskId, attachments}) {
    try {
      const {update} = getEnv(self).api.Tasks;
      const data = {
        attachments
      };
      const res = yield update(taskId, {data});
      const task = _cloneDeep(self.detailedTask);
      task.data.attachments = attachments;
      self.detailedTask = task;
      return res.task;

    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Attachments Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const transitionTask = flow(function* ({taskId, transitionName, transitionData, isFromList, setStatusTo}) {
    try {
      const {transition} = getEnv(self).api.Tasks;
      const response = yield transition({taskId, transitionName, transitionData});

      self.detailedTask = response.task;
      self.detailedTaskTransitions = response.Transitions;
      if (isFromList) {
        self.tasks.map((task) => {
          if (task.id === taskId) {
            task.status = setStatusTo;
          }
        })
      }
    } catch (error) {
      getEnv(self).monitoring.captureMessage(`Transition Task Failed, => ${transitionName}`);
      getEnv(self).monitoring.captureException(error);
      return error
      console.error(error);
    }
  });

  const sendComment = flow(function* ({taskId, message}) {
    try {
      const {sendComment} = getEnv(self).api.Tasks;
      const response = yield sendComment(taskId, {description: message});
      self.detailedTask = response.task;
      self.detailedTaskTransitions = response.Transitions;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Sending Comment Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const removeAsync = flow(function* (id) {
    try {
      const {remove} = getEnv(self).api.Tasks;
      yield remove(id);
      getTasks(self.currentParams);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const clearDetailedTask = () => {
    self.detailedTask = undefined;
  };

  const filterSubtasks = ({ page = 0, pagesize = 10, sort = [], filter = { status: 'all' }} = 0) => {
    console.log(filter)
    if (!self.detailedTask) {
      return [];
    }
    const { detailedTask } = self;

    const { subtasks } = detailedTask;

    if (!subtasks) {
      return [];
    }

    if (!subtasks.length) {
      return [];
    }

    const rootStore = getParent(self);
    const { generalStore } = rootStore;

    return subtasks.slice(pagesize * page, pagesize).filter(x => filter.status === 'all' || filter.status === x.status)
    .map((task) => {
      const deadlineClient = _get(task, ['data', 'deadline', 'client']);
      const _daysToDeadline = dateDiffFromNow(deadlineClient);

      return {
        ...task,

        _readableStatus: generalStore.getTaskStatusNameById(task.status),
        _readableDeadline: renderDateSafe(deadlineClient),
        _daysToDeadline: _daysToDeadline,
      };
    }).sort((a,b) => {
      const getDate = x => new Date(_get(x, ['data', 'deadline', 'client']))
      return (
        sort === 'date desc' ? getDate(b) - getDate(a) :
        sort === 'date asc' ? getDate(a) + getDate(b) :
        sort === 'deadline desc' ? b._daysToDeadline + a._daysToDeadline :
        sort === 'deadline asc' ? a._daysToDeadline + b._daysToDeadline :
        sort === 'name desc' ? b.name + a.name :
        a.name + b.name
        )
    })
  }

  return {
    getTasks,
    nextPage,
    saveTask,
    getTaskById,
    clearDetailedTask,
    updateTask,
    transitionTask,
    sendComment,
    updateAttachments,
    getClientTasksAsync,
    getSubtasksAsync,
    removeAsync,
    filterSubtasks,
    updateFiles
  }
};

const Views = (self) => ({
  get tableData() {
    const rootStore = getParent(self);
    const {generalStore} = rootStore;
    return self.tasks.map((task) => {
      const taskName = [task.name];

      if (task.parent_name) {
        taskName.push(task.parent_name);
      }

      return {
        ...task,
        created_at: task.created_at,
        status: generalStore.getTaskStatusNameById(task.status),
        originStatus: task.status
      }
    });
  },

  get getSubTasksForParent() {
    if (!self.detailedTask) {
      return [];
    }
    const {detailedTask} = self;

    const {subtasks} = detailedTask;

    if (!subtasks) {
      return [];
    }

    if (!subtasks.length) {
      return [];
    }

    const rootStore = getParent(self);
    const {generalStore} = rootStore;

    return subtasks.map((task) => {
      const deadlineClient = _get(task, ['data', 'deadline', 'client']);
      const _daysToDeadline = dateDiffFromNow(deadlineClient);

      return {
        ...task,

        _readableStatus: generalStore.getTaskStatusNameById(task.status),
        _readableDeadline: renderDateSafe(deadlineClient),
        _daysToDeadline: _daysToDeadline,
      };
    }).sort((a, b) => new Date(_get(b, ['data', 'deadline', 'client'])) - new Date(_get(a, ['data', 'deadline', 'client'])));
  },

  get getTaskComments() {
    if (!self.detailedTask) {
      return [];
    }
    const {detailedTask} = self;

    const {comments = []} = detailedTask;

    if (!comments.length) {
      return [];
    }

    return comments.slice().sort((a, b) => (new Date(_get(b, 'created_at')) - new Date(_get(a, 'created_at'))));
  },

  get getTaskHistory() {
    if (!self.detailedTask) {
      return [];
    }
    const {detailedTask} = self;

    const {history = []} = detailedTask;

    if (!history.length) {
      return [];
    }

    return history.slice().sort((a, b) => (new Date(_get(b, 'created_at')) - new Date(_get(a, 'created_at'))));
  },

  get getCompletedSubtasksCount() {
    if (!self.detailedTask) {
      return 0;
    }

    const {detailedTask} = self;
    const {subtasks} = detailedTask;

    return subtasks.filter(({status}) => status === 'completed' || status === 'sentToClient').length;
  }
});

export const TaskStore = t
  .model("TaskStore", Model)
  .actions(Actions)
  .views(Views);
