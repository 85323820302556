import {types as t, getEnv, flow, getSnapshot, applySnapshot} from "mobx-state-tree"

import { AbilityBuilder, Ability } from '@casl/ability'


function defineAbilityFor(user) { //(user)
  const { rules, can } = AbilityBuilder.extract();

  if (!user) {
    return new Ability(rules);
  }

  let role = user.role;
  if(role === 'admin' && !user.director){
    role = 'manager';
  }

  if (role === 'admin') {
    can(["read"], "Tasks");
    can(["read"], "Calendar");
    can(["update.manager"], "Task.Edit");
    can(["read.client.name", "read.client.deadline", "read.editor.deadline",  "read.author.deadline",  "read.budget", "update", "delete", "create"], "Task.List");
    can(["read.budget.author", 'read.partner.name', "read.budget.editor", "read.client.name", "read.subtasks", "read.process", "read.budget", "read.debt", "update", "update.rating", "update.files.source"], "Task.Detailed");

    can(["read"], "Users.List");
    can(["read", "create", "edit"], "Clients.List");

    can(["read", "write"], "Accounting");
    can(["read", "update", "delete", "pay", "create"], "Invoice.List");


    can(["update.source", "update.working", "update.final"], "Task.Attach");

    can(["update.client.managers"], "Client.Edit");

    can(["read"], "Calendar.View");
    can(["read.editor.name"], "Task.Detailed");
    can(["read.history.email"], "Task.Detailed");

    can(["read, update"], "MailTemplate");
  }

  if (role === 'manager') {
    can(["read"], "Tasks");
    can(["read"], "Calendar");
    can(["read.client.name", "read.client.deadline", "read.editor.deadline", "read.editor.date",  "read.author.deadline", "read.budget", "update", "delete", "create"], "Task.List");
    can(["read.budget.author", "read.budget.editor", "read.client.name", "read.subtasks", "read.process", "read.budget", "read.debt", "update", "update.rating", "update.files.source"], "Task.Detailed");
    can(["read", "create", "edit"], "Clients.List");
    can(["read"], 'Partners.List');
    can(["read", "update", "delete", "pay", "create"], "Invoice.List");

    can(["update.source", "update.working", "update.final"], "Task.Attach");

    can(["read"], "Calendar.View");

    can(["read.editor.name"], "Task.Detailed");
  }

  if (role === 'author') {
    can(["read"], "Tasks");
    can(["read"], "Calendar");
    can(["read.budget.author", "read.rating.editor", "read.rating.client"], "Task.Detailed");
    can(["update.working", "update.final"], "Task.Attach");
    can(["read.author.deadline"], "Task.List");
    can(["read", "update", "create"], "Invoice.List");
  }

  if (role === 'editor') {
    can(["read"], "Tasks");
    can(["read"], "Calendar");
    can(["update.working", "update.final"], "Task.Attach");
    can(["read.author.deadline", "read.editor.deadline"], "Task.List");
    can(["read.budget.editor", "read.rating.editor", "read.rating.client"], "Task.Detailed");
    can(["read", "update", "create"], "Invoice.List");
    can(["read.editor.name", "read.budget.editor", "read.budget.editor"], "Task.Detailed");
  }

  if(role === 'partner') {
    can(["read"], "Clients.List");
    can(["read"], "Invoice.List");
  }

  return new Ability(rules);
}

const Model = {
  isAuthenticated: false,
  me: t.frozen(),
  token: t.maybe(t.string),

  permissions: t.frozen(),


  loginError: t.frozen()
};

const Actions = (self) => {
  const afterCreate = () => {
    const authenticationData = window.localStorage.getItem('wf-authentication');

    if (authenticationData) {
      const jsonData = JSON.parse(authenticationData);
      applySnapshot(self, jsonData);

      self.permissions = defineAbilityFor(jsonData.me);
    } else {
      self.permissions = defineAbilityFor();
    }
  };

  const login = flow(function*({login, password}) {

    try {
      self.loginError = false;
      const response = yield getEnv(self).api.Auth.login({ email: login, password });
      const { user } = response;
      self.isAuthenticated = true;
      self.token = user.token;
      self.me = user;
      self.permissions = defineAbilityFor(user);
      const authenticationData = getSnapshot(self);
      window.localStorage.setItem('wf-authentication', JSON.stringify(authenticationData));
      window.location = '/';
      //location.reload();
    } catch (error) {
      self.loginError = true;
      getEnv(self).monitoring.addBreadcrumb({message: 'Login failed Failed'});
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const changeUserData = user => {
    self.me = user;

    const authenticationData = getSnapshot(self);
    if (authenticationData) {
      applySnapshot(self, authenticationData);

      self.permissions = defineAbilityFor(self.me);
      window.localStorage.setItem('wf-authentication', JSON.stringify(authenticationData));
    }
  };

  const logout = () => {
    self.permissions = defineAbilityFor();
    self.isAuthenticated = false;
    self.token = '';
    self.me = false;
    window.localStorage.removeItem('wf-authentication');

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return { afterCreate, login, logout, changeUserData }
};

const Views = (self) => ({
  get myUser() {

    return {
      id: self.me.id,
      name: [self.me.firstname, self.me.lastname].join(' ')
    }
  }
});

export const AuthStore = t
  .model("AuthStore", Model)
  .actions(Actions)
  .views(Views);

