import { types as t, getEnv, flow } from "mobx-state-tree"

const LookupClientsModel = t.model("LookupClientsModel", {
  isLoading: false,
  list: t.array(t.frozen())
});

const Client = t.model("Client", {
  id: t.identifierNumber,
  companyname: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  firstname: t.maybeNull(t.string),
  lastname: t.maybeNull(t.string),
  status: t.maybeNull(t.string)
});

const Model = {
  // this way modal is MUTABLE, beware
  lookupClients: t.optional(LookupClientsModel, {isLoading: false, list: []}),
  lookupClientsList: t.frozen(),
  clientListIsLoading: false,
  clientList: t.optional(t.array(Client), []),
  pages: t.optional(t.number, 0),
  total: t.optional(t.number, 0),
  detailedClient: t.frozen(),
  detailedClientCreateError: t.frozen()
};

const Actions = (self) => {
  const lookup = flow(function* (clientNamePart) {
    try {
      const { lookup } = getEnv(self).api.General;
      self.lookupClients.isLoading = true;

      const response = yield lookup({field: 'client', value: clientNamePart});
      self.lookupClients.list = response.lookup;
      self.lookupClientsList = response.lookup;

      self.lookupClients.isLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Lookup Clients Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getClientsAsync = flow(function* ({pagesize = 10, page = 0, sort = [], filters = {}} = {}) {
    try {
      self.clientListIsLoading = true;
      const { getClients } = getEnv(self).api.Clients;
      const res = yield getClients({pagesize, page, sort, filters});
      
      if(page > res.pages || page === res.pages) {
        const normalizePage = res.pages === 1 ? 0 : res.pages;
        const newResponse = yield getClients({pagesize, normalizePage, sort, filters});

        self.clientList = newResponse.rows;
        self.pages = newResponse.pages;
        self.total = newResponse.total;
        
        self.clientListIsLoading = false;
      } else {
        self.clientList = res.rows;
        self.pages = res.pages;
        self.total = res.total;

        self.clientListIsLoading = false;
      }

    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Clients Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getClientByIdAsync = flow(function* (id) {
    try {
      const {getById} = getEnv(self).api.Clients;
      const res = yield getById(id);
      self.detailedClient = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getClientById =  async (id) => {
    const {getById} = getEnv(self).api.Clients;
    return getById(id);
  }

  const updateAsync = flow(function* ({id, data}) {
    try {
      self.detailedClientCreateError = false;
      const {update} = getEnv(self).api.Clients;
      yield update(id, data);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedClientCreateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const createAsync = flow(function* (data) {
    try {
      self.detailedClientCreateError = false;
      const {create} = getEnv(self).api.Clients;

      return yield create(data)
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedClientCreateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const removeAsync = flow(function* (id) {
    try {
      self.detailedClientCreateError = false;
      const {remove} = getEnv(self).api.Clients;
      yield remove(id);
      getClientsAsync();
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Creating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedClientCreateError = error.response && error.response.body && error.response.body.errors;
    }
  });


  const clearDetailed = () => {
    self.detailedClient = false;
  };

  return { lookup, getClientsAsync, getClientByIdAsync, updateAsync, createAsync, clearDetailed, removeAsync, getClientById }
};

const Views = (self) => ({

});

export const ClientStore = t
  .model("ClientStore", Model)
  .actions(Actions)
  .views(Views);

