import { types as t, getEnv, flow } from "mobx-state-tree"

const InvoiceModel = t.model("InvoiceModel", {
  isLoading: false,
  list: t.array(t.frozen())
});

const Model = {
  invoicesList: t.optional(InvoiceModel, {isLoading: false, currentParams:null, list: []}),
  pages: t.optional(t.number, 0),
  total: t.optional(t.number, 0),
  detailedInvoiceLoading: t.optional(t.boolean, false),
  detailedInvoice: t.frozen(),
  tasksForNew: t.frozen(),
  detailedUpdateError: t.frozen()
};

const Actions = (self) => {

  const getInvoicesAsync = flow(function* ({pagesize = 10, page = 0, sort = [], filters = {}} = {}) {
    try {
      const { getList } = getEnv(self).api.Invoices;
      self.invoicesList.isLoading = true;
      self.invoicesList.currentParams = {pagesize, page, sort, filters};

      const res = yield getList({pagesize, page, sort, filters});
      //console.log('GOT IT', res);

      self.invoicesList.list = res.rows;
      self.pages = res.pages;
      self.total = res.total;
      self.invoicesList.isLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getInvoiceSingleAsync = flow(function* (id) {
    try {
      const { getById } = getEnv(self).api.Invoices;
      self.detailedInvoiceLoading = true;

      const res = yield getById(id);

      self.detailedInvoice = res;
      self.detailedInvoiceLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const getNewInvoiceAsync = flow(function* () {
      self.detailedInvoice = null;
      self.detailedInvoiceLoading = false;
  });

  const getNewInvoiceTasksAsync = flow(function* () {
    try {
      const { getTasks } = getEnv(self).api.Invoices;
      self.detailedInvoiceLoading = true;

      const res = yield getTasks();

      self.tasksForNew = res.rows;
      self.detailedInvoiceLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });
  const getPartnerInvoiceTasksAsync = flow(function* (id) {
    try {
      const { getUserTasks } = getEnv(self).api.Invoices;
      self.detailedInvoiceLoading = true;

      const res = yield getUserTasks(id);

      self.tasksForNew = res.rows;
      self.detailedInvoiceLoading = false;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const resetInvoiceTasks = flow(function* () {
    self.tasksForNew = null;
  })

  const saveInvoiceAsync = flow(function* (data) {
    try {
      self.detailedUpdateError = false;
      const {update, create} = getEnv(self).api.Invoices;
      let res = null;
      if (data.id) {
        res = yield update(data.id, data);
      } else {
        res = yield create(data);
      }
      return res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUpdateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const sendComment = flow(function* (data) {
    try {
      self.detailedUpdateError = false;
      const {sendComment} = getEnv(self).api.Invoices;
      console.log('SEND COMMENT', data);
      const res = yield sendComment(data.id, data);
      self.detailedInvoice = res;
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUpdateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const transitionInvoiceAsync = flow(function* (id, transitionName, data, isFromList) {
    try {
      self.detailedUpdateError = false;
      const {transition} = getEnv(self).api.Invoices;
      const res = yield transition(id, transitionName, data);
      self.detailedInvoice = res;
      if (isFromList) {
        getInvoicesAsync(self.invoicesList.currentParams);
      }
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Updating Client Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
      self.detailedUpdateError = error.response && error.response.body && error.response.body.errors;
    }
  });

  const removeInvoiceAsync = flow(function* (id) {
    try {
      const { remove } = getEnv(self).api.Invoices;

      yield remove(id);
      getInvoicesAsync(self.invoicesList.currentParams);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });

  const createInvoice = flow(function* (data) {
    try {
      const { makeInvoice } = getEnv(self).api.Invoices;

      yield makeInvoice(data);
      getInvoicesAsync(self.invoicesList.currentParams);
    } catch (error) {
      getEnv(self).monitoring.captureMessage('Loading Failed');
      getEnv(self).monitoring.captureException(error);
      console.error(error);
    }
  });


  return {
    getInvoicesAsync,
    getNewInvoiceAsync,
    getInvoiceSingleAsync,
    saveInvoiceAsync,
    getNewInvoiceTasksAsync,
    sendComment,
    transitionInvoiceAsync,
    removeInvoiceAsync,
    createInvoice,
    getPartnerInvoiceTasksAsync,
    resetInvoiceTasks
  };
};

const Views = (self) => ({

});

export const InvoiceStore = t
  .model("InvoiceStore", Model)
  .actions(Actions)
  .views(Views);

