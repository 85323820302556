import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import Loadable from 'react-loadable';
import moment from 'moment';
import momentRuLocal from 'moment/locale/ru';
import momentUaLocal from 'moment/locale/uk'
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-rater/lib/react-rater.css'
import "./assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/sass/styles.scss";

const active = window.localStorage.getItem('locale') || 'ru';
if (active === 'uk') {
  moment().locale('uk', momentUaLocal)
} else if (active === 'ru') {
  moment().locale('ru', momentRuLocal)
} else {
  moment().locale(active)
}

const LoadableAuthLayout = Loadable({
  loader: () => import ('./layouts/Auth.jsx'),
  loading: () => null,
});

const LoadableAdminLayout = Loadable({
  loader: () => import ('./layouts/Admin.jsx'),
  loading: () => null,
});

const PublicApp = () => {
  return (
    <Switch>
      <Route path="/" render={props => <LoadableAuthLayout {...props} />}/>
    </Switch>
  )
};

const PrivateApp = () => {
  return (
    <Switch>
      <Route path="/" render={props => <LoadableAdminLayout {...props} />}/>
    </Switch>
  )
};


const App = inject('store')(observer(({store: {authStore}}) => {
  if (authStore.isAuthenticated) {
    return <PrivateApp/>;
  } else {
    return <PublicApp/>;
  }

}));

export default App;
